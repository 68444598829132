<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="ml-5">
        <v-btn @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>Назад</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-card class="mx-auto my-12" max-width="1074">
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <!--        <v-img-->
        <!--          height="250"-->
        <!--          src="https://cdn.vuetifyjs.com/images/cards/cooking.png"-->
        <!--        ></v-img>-->

        <!--        <v-card-title>Тариф {{ this.tariff.name }}</v-card-title>-->

        <v-card-text>
          <v-row align="center" class="mx-0">
            <v-rating
              :value="4.5"
              color="amber"
              dense
              half-increments
              readonly
              size="14"
            ></v-rating>

            <div class="grey--text ms-4">4.5 (413)</div>
          </v-row>

          <div class="my-4 text-subtitle-1">$ • Italian, Cafe</div>

          <div>
            Small plates, salads & sandwiches - an intimate setting with 12
            indoor seats plus patio seating.
          </div>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-title>Tonight's availability</v-card-title>

        <v-card-text>
          <v-chip-group
            v-model="selection"
            active-class="deep-purple accent-4 white--text"
            column
          >
            <v-chip>5:30PM</v-chip>

            <v-chip>7:30PM</v-chip>

            <v-chip>8:00PM</v-chip>

            <v-chip>9:00PM</v-chip>
          </v-chip-group>
        </v-card-text>

        <v-card-actions>
          <v-btn color="deep-purple lighten-2" text @click="reserve">
            Reserve
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <div class="row">
      <div class="col-3">
        <h3>Draggable 1</h3>
        <draggable tag="v-layout" :list="list1" @change="log">
          <div class="" v-for="(element, index) in list1" :key="element.name">
            {{ element.name }} {{ index }}
          </div>
        </draggable>
      </div>

      <div class="col-3">
        <h3>Draggable 2</h3>
        <draggable tag="v-layout" :list="list2" @change="log">
          <div class="" v-for="(element, index) in list2" :key="element.name">
            {{ element.name }} {{ index }}
          </div>
        </draggable>
      </div>

      <!--      <rawDisplayer class="col-3" :value="list1" title="List 1" />-->
      <!---->
      <!--      <rawDisplayer class="col-3" :value="list2" title="List 2" />-->
    </div>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";

export default {
  display: "Two Lists",
  order: 1,
  name: "Config",
  components: {
    draggable,
  },
  data: () => {
    return {
      selection: "",
      reserve: "",
      list1: [
        { name: "John", id: 1 },
        { name: "Joao", id: 2 },
        { name: "Jean", id: 3 },
        { name: "Gerard", id: 4 },
      ],
      list2: [
        { name: "Juan", id: 5 },
        { name: "Edgard", id: 6 },
        { name: "Johnson", id: 7 },
      ],
    };
  },
  created() {
    this.$store.dispatch("load_tariff", this.$route.params.tariff_id);
  },
  methods: {
    add: function () {
      this.list.push({ name: "Juan" });
    },
    replace: function () {
      this.list = [{ name: "Edgard" }];
    },
    clone: function (el) {
      return {
        name: el.name + " cloned",
      };
    },
    log: function (evt) {
      window.console.log(evt);
    },
  },
};
</script>

<style scoped></style>
